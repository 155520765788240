// ResumeDetailModal.js
import React from "react";
import Modal from "./Modal";

const ResumeDetailModal = ({ isOpen, onClose, resume }) => {
  if (!resume) return null;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <h2 className="text-lg font-bold mb-4">Resume Details</h2>
      <p>
        <strong>Name:</strong> {resume.name}
      </p>
      <p>
        <strong>Email:</strong> {resume.email}
      </p>
      <p>
        <strong>Phone:</strong> {resume.phone}
      </p>
      <p>
        <strong>Country:</strong> {resume.country}
      </p>
      <p>
        <strong>Cover Letter:</strong> {resume.coverLetter}
      </p>
      <p>
        <strong>Role:</strong> {resume.role}
      </p>
      <p>
        <strong>Message:</strong> {resume.message}
      </p>
      <p>
        <strong>Status:</strong> {resume.status}
      </p>
      <p>
        <strong>Resume:</strong>{" "}
        <a
          href={resume.resumeURL}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-600 hover:text-blue-900"
        >
          View Resume
        </a>
      </p>
      <p>
        <strong>Created At:</strong>{" "}
        {new Date(resume.createdAt).toLocaleString()}
      </p>
    </Modal>
  );
};

export default ResumeDetailModal;
