import React, { useState, useEffect } from "react";
import { FaTrash } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function AssignmentList() {
  const [assignments, setAssignments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [assignmentToDelete, setAssignmentToDelete] = useState(null); // For delete confirmation
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  // Fetch assignments when component mounts
  useEffect(() => {
    fetchAssignments();
  }, []);

  const fetchAssignments = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://website-backend.starlabs.co.in/api/assignments"
      );
      const data = await response.json();
      setAssignments(data.assignments || []);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching assignments:", error);
      toast.error("Failed to fetch assignments.");
      setLoading(false);
    }
  };

  const openDeleteModal = (assignment) => {
    setAssignmentToDelete(assignment);
    setShowDeleteModal(true);
  };

  const handleDeleteAssignment = async () => {
    try {
      const response = await fetch(
        `https://website-backend.starlabs.co.in/api/assignments/${assignmentToDelete.id}`,
        {
          method: "DELETE",
        }
      );
      const data = await response.json();
      if (data.message === "Assignment deleted successfully") {
        toast.success("Assignment deleted successfully");
        setAssignments(
          assignments.filter((a) => a.id !== assignmentToDelete.id)
        );
        setShowDeleteModal(false);
      } else {
        toast.error("Failed to delete assignment");
      }
    } catch (error) {
      toast.error("Error deleting assignment");
    }
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-6">
      <h2 className="text-lg font-bold mb-4">Assignment List</h2>

      {loading ? (
        <div className="flex justify-center items-center h-64">
          <div className="flex space-x-3">
            {/* Loading animation */}
            <div className="w-4 h-4 bg-blue-400 rounded-full animate-pulse"></div>
            <div className="w-4 h-4 bg-blue-400 rounded-full animate-pulse delay-150"></div>
            <div className="w-4 h-4 bg-blue-400 rounded-full animate-pulse delay-300"></div>
          </div>
        </div>
      ) : (
        <>
          <div className="overflow-x-auto">
            <table className="table-auto min-w-full bg-white border border-gray-300">
              <thead>
                <tr className="bg-gray-100">
                  <th className="py-2 px-4 border-b">Name</th>
                  <th className="py-2 px-4 border-b">Email</th>
                  <th className="py-2 px-4 border-b">Applied For</th>
                  <th className="py-2 px-4 border-b">Link</th>
                  <th className="py-2 px-4 border-b">Status</th>
                  <th className="py-2 px-4 border-b">Created At</th>
                  <th className="py-2 px-4 border-b">Actions</th>
                </tr>
              </thead>
              <tbody>
                {assignments.map((assignment) => (
                  <tr key={assignment.id} className="hover:bg-gray-50">
                    <td className="py-2 px-4 border-b break-words">
                      {assignment.name}
                    </td>
                    <td className="py-2 px-4 border-b break-words">
                      {assignment.email}
                    </td>
                    <td className="py-2 px-4 border-b break-words">
                      {assignment.appliedFor}
                    </td>
                    <td className="py-2 px-4 border-b break-words">
                      <a
                        href={assignment.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {assignment.link}
                      </a>
                    </td>
                    <td className="py-2 px-4 border-b break-words">
                      {assignment.status}
                    </td>
                    <td className="py-2 px-4 border-b break-words">
                      {assignment.createdAt}
                    </td>
                    <td className="py-2 px-4 border-b text-center">
                      <div className="flex justify-center items-center gap-2">
                        <button
                          onClick={() => openDeleteModal(assignment)}
                          className="text-red-600 hover:text-red-900"
                        >
                          <FaTrash className="text-red-600" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}

      {/* Delete Confirmation Modal */}
      {showDeleteModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white rounded-lg p-6">
            <h3 className="text-lg font-bold mb-4">Confirm Delete</h3>
            <p>
              Are you sure you want to delete the assignment of{" "}
              {assignmentToDelete?.name}?
            </p>
            <div className="flex justify-end mt-4">
              <button
                onClick={() => setShowDeleteModal(false)}
                className="bg-gray-300 text-gray-800 py-1 px-3 rounded mr-2 hover:bg-gray-400"
              >
                Cancel
              </button>
              <button
                onClick={handleDeleteAssignment}
                className="bg-red-500 text-white py-1 px-3 rounded hover:bg-red-600"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}

      <ToastContainer />
    </div>
  );
}
