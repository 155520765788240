import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { FaInfoCircle, FaEdit, FaTrash, FaVideo, FaFile } from "react-icons/fa";
import "react-toastify/dist/ReactToastify.css";
import MeetingDetailsModal from "./MeetingDetailsModal";
import MeetingResumeView from "./MeetingResumeView";
import DeleteConfirmationModal from "../Resume/DeleteConfirmationModal";

export default function MeetingList() {
  const [meetings, setMeetings] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [filterStatus, setFilterStatus] = useState("unscheduled");
  const [pageRange, setPageRange] = useState([1, 10]);
  const [isMeetingModalOpen, setIsMeetingModalOpen] = useState(false);
  const [selectedMeetingId, setSelectedMeetingId] = useState(null);

  const [isViewModalOpen, setViewModalOpen] = useState(false);
  const [resumeURL, setResumeURL] = useState("");
  const [fileType, setFileType] = useState("");
  const [selectedJobRole, setSelectedJobRole] = useState("");

  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteMeetingId, setDeleteMeetingId] = useState(null);

  const openDeleteModal = (id) => {
    setDeleteMeetingId(id);
    setDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
    setDeleteMeetingId(null);
  };

  const handleDeleteMeeting = async () => {
    try {
      const response = await fetch(
        "https://website-backend.starlabs.co.in/api/meetings",
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ id: deleteMeetingId }),
        }
      );

      if (response.ok) {
        toast.success("Meeting deleted successfully.");
        fetchMeetings(currentPage, filterStatus);
      } else {
        toast.error("Failed to delete the meeting.");
      }
    } catch (error) {
      console.error("Error deleting meeting:", error);
      toast.error("An error occurred while deleting the meeting.");
    } finally {
      closeDeleteModal();
    }
  };

  useEffect(() => {
    fetchMeetings(currentPage, filterStatus);
  }, [currentPage, filterStatus]);

  const fetchMeetings = async (page, status) => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://website-backend.starlabs.co.in/api/meetings?status=${status}&page=${page}&limit=10`
      );
      const data = await response.json();
      setMeetings(data.meetingDetails || []);
      setTotalPages(data.totalPages || 1);
    } catch (error) {
      console.error("Error fetching meetings:", error);
      toast.error("Failed to fetch meetings.");
    } finally {
      setLoading(false);
    }
  };

  const groupMeetingsByDate = (meetings) => {
    if (!Array.isArray(meetings)) return {};
    return meetings.reduce((acc, meeting) => {
      const date = new Date(meeting.date).toLocaleDateString();
      if (!acc[date]) acc[date] = [];
      acc[date].push(meeting);
      return acc;
    }, {});
  };

  const handlePageClick = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handleRangeNext = () => {
    const [start, end] = pageRange;
    if (end < totalPages) {
      setPageRange([start + 10, Math.min(end + 10, totalPages)]);
    }
  };

  const handleRangePrev = () => {
    const [start, end] = pageRange;
    if (start > 1) {
      setPageRange([Math.max(start - 10, 1), end - 10]);
    }
  };

  const openMeetingModal = (id) => {
    setSelectedMeetingId(id);
    setIsMeetingModalOpen(true);
  };

  const closeMeetingModal = () => {
    setIsMeetingModalOpen(false);
    setSelectedMeetingId(null);
    fetchMeetings(currentPage, filterStatus);
  };

  const handleViewResume = (resumeURL, jobRole, fileType) => {
    console.log(resumeURL, "kajbhjagdh");
    console.log(jobRole, "jabdnd");
    setResumeURL(resumeURL);
    setFileType(fileType);
    setSelectedJobRole(jobRole);
    setViewModalOpen(true);
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-6">
      <h2 className="text-lg font-bold mb-4">Meeting List</h2>

      {/* Filter */}
      <div className="flex justify-between items-center mb-4">
        <select
          value={filterStatus}
          onChange={(e) => setFilterStatus(e.target.value)}
          className="border border-gray-300 rounded px-2 py-1 pr-6 w-48"
        >
          <option value="unscheduled">Unscheduled</option>
          <option value="scheduled">Scheduled</option>
        </select>
      </div>

      {/* Table */}
      {loading ? (
        <div className="flex justify-center items-center h-64">Loading...</div>
      ) : (
        <>
          <div className="overflow-x-auto">
            <table className="table-auto min-w-full bg-white border border-gray-300">
              <thead>
                <tr className="bg-gray-100">
                  <th className="py-2 px-4 border-b">Name</th>
                  <th className="py-2 px-4 border-b">Email</th>
                  <th className="py-2 px-4 border-b">Role</th>
                  <th className="py-2 px-4 border-b">Date</th>
                  <th className="py-2 px-4 border-b">Time</th>
                  <th className="py-2 px-4 border-b">Status</th>
                  <th className="py-2 px-4 border-b">Resume</th>
                  <th className="py-2 px-4 border-b">Actions</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(groupMeetingsByDate(meetings) || {}).map(
                  ([date, meetings]) => (
                    <React.Fragment key={date}>
                      <tr className="bg-gray-200">
                        <td
                          colSpan="8"
                          className="py-2 px-4 font-bold text-center"
                        >
                          {date}
                        </td>
                      </tr>
                      {meetings.map((meeting) => (
                        <tr key={meeting.id}>
                          <td className="py-2 px-4 border-b">{meeting.name}</td>
                          <td className="py-2 px-4 border-b">
                            {meeting.email}
                          </td>
                          <td className="py-2 px-4 border-b">{meeting.role}</td>
                          <td className="py-2 px-4 border-b">
                            {new Date(meeting.date).toLocaleDateString()}
                          </td>
                          <td className="py-2 px-4 border-b">{meeting.time}</td>
                          <td className="py-2 px-4 border-b">
                            {meeting.status}
                          </td>
                          <td className="py-2 px-4 border-b text-center">
                            <button
                              onClick={() =>
                                handleViewResume(
                                  meeting.resumeUrl,
                                  meeting.role,
                                  meeting.fileType
                                )
                              }
                              className="text-blue-500 hover:underline"
                            >
                              <FaFile />
                            </button>
                          </td>
                          <td className="py-2 px-4 border-b">
                            <button
                              onClick={() => openMeetingModal(meeting.id)}
                              className="text-blue-500 hover:underline mr-2"
                            >
                              <FaInfoCircle />
                            </button>
                            <button
                              onClick={() => openDeleteModal(meeting.id)}
                              className="text-red-500 hover:underline"
                            >
                              <FaTrash />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </React.Fragment>
                  )
                )}
              </tbody>
            </table>
          </div>

          {/* Pagination */}
          <div className="flex justify-center items-center mt-4">
            <div className="w-96">
              <div className="flex justify-between items-center mb-2">
                <button
                  onClick={handleRangePrev}
                  disabled={pageRange[0] === 1}
                  className="border border-gray-300 rounded px-3 py-1"
                >
                  Prev
                </button>
                {Array.from(
                  { length: pageRange[1] - pageRange[0] + 1 },
                  (_, index) => {
                    const pageNumber = pageRange[0] + index;
                    const isActive = pageNumber === currentPage;
                    const isFaded = pageNumber > totalPages;

                    return (
                      <button
                        key={pageNumber}
                        onClick={() => handlePageClick(pageNumber)}
                        className={`border border-gray-300 rounded px-3 py-1 mx-1 ${
                          isActive
                            ? "bg-blue-500 text-white"
                            : isFaded
                            ? "text-gray-400"
                            : "hover:bg-blue-500 hover:text-white"
                        }`}
                        style={{ opacity: isFaded ? 0.5 : 1 }}
                        disabled={isFaded}
                      >
                        {pageNumber}
                      </button>
                    );
                  }
                )}
                <button
                  onClick={handleRangeNext}
                  disabled={pageRange[1] >= totalPages}
                  className="border border-gray-300 rounded px-3 py-1"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      <MeetingDetailsModal
        isOpen={isMeetingModalOpen}
        onClose={closeMeetingModal}
        meetingId={selectedMeetingId}
        onUpdate={closeMeetingModal} // Refresh the meeting list on update
      />
      <MeetingResumeView
        isOpen={isViewModalOpen}
        onClose={() => setViewModalOpen(false)}
        resumeURL={resumeURL}
        fileType={fileType}
        jobRole={selectedJobRole}
      />
      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={closeDeleteModal}
        onConfirm={handleDeleteMeeting}
        message="Are you sure you want to delete this meeting?"
      />

      <ToastContainer />
    </div>
  );
}
