// DeleteConfirmationModal.js
import React from "react";
import Modal from "./Modal";

const DeleteConfirmationModal = ({ isOpen, onClose, onConfirm }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <h2 className="text-lg font-bold mb-4">Confirm Deletion</h2>
      <p>Are you sure you want to delete this resume?</p>
      <div className="flex justify-end gap-2 mt-4">
        <button
          className="bg-gray-300 text-gray-700 py-2 px-4 rounded"
          onClick={onClose}
        >
          Cancel
        </button>
        <button
          className="bg-red-500 text-white py-2 px-4 rounded"
          onClick={onConfirm}
        >
          Delete
        </button>
      </div>
    </Modal>
  );
};

export default DeleteConfirmationModal;
