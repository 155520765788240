import React from "react";

export default function DetailsJobModal({ isOpen, job, onClose }) {
  if (!isOpen || !job) return null;

  const handleBackgroundClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose(); // Close the modal when clicking on the background
    }
  };

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
      onClick={handleBackgroundClick}
    >
      <div className="bg-white p-6 rounded-md shadow-md max-w-md w-full max-h-screen overflow-y-auto">
        <h2 className="text-lg font-bold mb-4">Job Details</h2>

        {/* Role */}
        <div className="mb-4">
          <label className="block text-gray-700">Role</label>
          <p>{job.role}</p>
        </div>

        {/* Location */}
        <div className="mb-4">
          <label className="block text-gray-700">Location</label>
          <p>{job.location}</p>
        </div>

        {/* Country */}
        <div className="mb-4">
          <label className="block text-gray-700">Country</label>
          <p>{job.country}</p>
        </div>

        {/* Experience */}
        <div className="mb-4">
          <label className="block text-gray-700">Experience</label>
          <p>{job.experience}</p>
        </div>

        {/* Description */}
        <div className="mb-4">
          <label className="block text-gray-700">Description</label>
          <p>{job.description}</p>
        </div>

        {/* Qualifications */}
        <div className="mb-4">
          <label className="block text-gray-700">Qualifications</label>
          <ul className="list-disc ml-5">
            {job.qualifications.map((qualification, index) => (
              <li key={index}>{qualification}</li>
            ))}
          </ul>
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Interviewees</label>
          <ul className="list-disc ml-5">
            {job.interviewees.map((interviewees, index) => (
              <li key={index}>{interviewees}</li>
            ))}
          </ul>
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Email Body</label>
          <p>{job.emailBody}</p>
        </div>

        {/* Close Button */}
        <button
          onClick={onClose}
          className="mt-4 bg-blue-600 text-white py-2 px-4 rounded"
        >
          Close
        </button>
      </div>
    </div>
  );
}
