import React, { useState, useEffect } from "react";
import { FaInfoCircle, FaEdit, FaTrash } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteConfirmationJobModal from "./DeleteConfirmationJobModal";
import DetailsJobModal from "./DetailsJobModal";
import EditJobModal from "./EditJobModal";

export default function ActiveJobsPanel() {
  const [jobs, setJobs] = useState([]); // State to hold the job list
  const [selectedJob, setSelectedJob] = useState(null);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isDetailsOpen, setIsDetailsOpen] = useState(false); // State for controlling the details modal
  const [isDeleteOpen, setIsDeleteOpen] = useState(false); // State for delete confirmation modal
  const [jobToDelete, setJobToDelete] = useState(null); // State to hold the job ID to be deleted

  const [editForm, setEditForm] = useState({
    role: "",
    description: "",
    qualifications: [""],
    location: "",
    experience: "",
    country: "",
    status: "",
    interviewees: [""],
    emailBody: "",
  });

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API}/jobs`);
        const data = await response.json();

        // Ensure qualifications and interviewees are parsed as arrays
        const parsedData = data.map((job) => ({
          ...job,
          qualifications: Array.isArray(job.qualifications)
            ? job.qualifications
            : [job.qualifications].filter(Boolean), // Avoid including null/undefined
          interviewees: Array.isArray(job.interviewees)
            ? job.interviewees
            : [job.interviewees].filter(Boolean), // Avoid including null/undefined
        }));

        setJobs(parsedData); // Set the fetched jobs to state
      } catch (error) {
        console.error("Error fetching jobs:", error);
      }
    };

    fetchJobs();
  }, []);

  // Open Edit Modal and set selected job details
  const handleEdit = (job) => {
    setSelectedJob(job);
    setEditForm({
      role: job.role,
      description: job.description,
      qualifications: job.qualifications.length > 0 ? job.qualifications : [""], // Ensure this is an array
      location: job.location || "",
      experience: job.experience || "",
      country: job.country || "",
      status: job.status,
      interviewees: job.interviewees.length > 0 ? job.interviewees : [""],
      emailBody: job.emailBody || "",
    });
    setIsEditOpen(true); // Open edit modal
  };

  // Open Delete Confirmation Modal
  const openDeleteConfirmation = (jobId) => {
    setJobToDelete(jobId);
    setIsDeleteOpen(true);
  };

  // Handle Delete Job
  const handleDelete = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API}/jobs`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: jobToDelete }),
      });

      if (response.ok) {
        // Remove the deleted job from state
        setJobs((prevJobs) => prevJobs.filter((job) => job.id !== jobToDelete));
        toast.success("Job deleted successfully!");
      } else {
        console.error("Error deleting job");
        toast.error("Failed to delete job.");
      }
    } catch (error) {
      console.error("Error deleting job:", error);
      toast.error("Error occurred while deleting the job.");
    } finally {
      setIsDeleteOpen(false); // Close the delete confirmation modal
      setJobToDelete(null); // Reset job to delete
    }
  };

  // Open Details Modal and fetch job details
  const handleViewDetails = async (jobId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API}/jobs/${jobId}`
      );
      const job = await response.json();

      // Ensure qualifications and interviewees are parsed as arrays
      const parsedJob = {
        ...job,
        qualifications: Array.isArray(job.qualifications)
          ? job.qualifications
          : [job.qualifications].filter(Boolean), // Avoid including null/undefined
        interviewees: Array.isArray(job.interviewees)
          ? job.interviewees
          : [job.interviewees].filter(Boolean), // Avoid including null/undefined
      };

      console.log(parsedJob, "ParsedJob");
      setSelectedJob(parsedJob); // Set the selected job details
      setIsDetailsOpen(true); // Open the details modal
    } catch (error) {
      console.error("Error fetching job details:", error);
    }
  };

  const handleUpdateJob = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API}/jobs`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: selectedJob.id,
          role: editForm.role,
          description: editForm.description,
          qualifications: editForm.qualifications, // Ensure this is an array
          location: editForm.location,
          experience: editForm.experience,
          country: editForm.country,
          status: editForm.status,
          interviewees: editForm.interviewees,
          emailBody: editForm.emailBody,
        }),
      });

      if (response.ok) {
        // Update the jobs state without converting qualifications to a string
        setJobs((prevJobs) =>
          prevJobs.map((job) =>
            job.id === selectedJob.id
              ? {
                  ...job,
                  role: editForm.role,
                  description: editForm.description,
                  qualifications: [...editForm.qualifications],
                  location: editForm.location,
                  experience: editForm.experience,
                  country: editForm.country,
                  status: editForm.status,
                  interviewees: [...editForm.interviewees],
                  emailBody: editForm.emailBody,
                }
              : job
          )
        );
        setIsEditOpen(false);
        toast.success("Job updated successfully!");
      } else {
        console.error("Error updating job");
        toast.error("Failed to update job.");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Error occurred while updating the job.");
    }
  };

  const handleQualificationChange = (index, value) => {
    const updatedQualifications = [...editForm.qualifications];
    updatedQualifications[index] = value;
    setEditForm({ ...editForm, qualifications: updatedQualifications });
  };

  const handleIntervieweesChange = (index, value) => {
    const updatedInterviewees = [...editForm.interviewees];
    updatedInterviewees[index] = value;
    setEditForm({ ...editForm, interviewees: updatedInterviewees });
  };

  const handleAddQualification = () => {
    setEditForm((prev) => ({
      ...prev,
      qualifications: [...prev.qualifications, ""], // Keep it an array
    }));
  };

  const handleAddInterviewees = () => {
    setEditForm((prev) => ({
      ...prev,
      interviewees: [...prev.interviewees, ""], // Keep it an array
    }));
  };

  const handleRemoveQualification = (index) => {
    const updatedQualifications = editForm.qualifications.filter(
      (_, i) => i !== index
    );
    setEditForm({ ...editForm, qualifications: updatedQualifications });
  };

  const handleRemoveInterviewees = (index) => {
    const updatedInterviewees = editForm.interviewees.filter(
      (_, i) => i !== index
    );
    setEditForm({ ...editForm, interviewees: updatedInterviewees });
  };

  const handleCloseEdit = () => {
    setIsEditOpen(false);
    setSelectedJob(null); // Reset selected job on close
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-6">
      <div className="mb-4">
        <h2 className="text-lg font-bold">Active Job Listings</h2>
      </div>
      <div className="overflow-x-auto">
        <table className=" table-auto min-w-full bg-white border border-gray-300">
          <thead>
            <tr className="bg-gray-100">
              <th className="py-2 px-4 border-b">Role</th>
              <th className="py-2 px-4 border-b">Experience</th>
              <th className="py-2 px-4 border-b">Location</th>
              <th className="py-2 px-4 border-b">Actions</th>
            </tr>
          </thead>
          <tbody>
            {jobs.map((job) => (
              <tr key={job.id} className="hover:bg-gray-50">
                <td className="py-2 px-4 border-b break-words">{job.role}</td>
                <td className="py-2 px-4 border-b break-words">
                  {job.experience}
                </td>
                <td className="py-2 px-4 border-b break-words">
                  {job.location}
                </td>
                <td className="py-2 px-4 border-b text-center align-middle break-words">
                  <div className="flex justify-center items-center gap-2">
                    <button
                      onClick={() => handleViewDetails(job.id)}
                      className="text-blue-600 hover:text-blue-900"
                    >
                      <FaInfoCircle className="text-blue-600" />
                    </button>
                    <button
                      onClick={() => handleEdit(job)}
                      className="text-blue-600 hover:text-blue-900"
                    >
                      <FaEdit className="text-green-600" />
                    </button>
                    <button
                      onClick={() => openDeleteConfirmation(job.id)} // Open delete confirmation
                      className="text-red-600 hover:text-red-900"
                    >
                      <FaTrash className="text-red-600" />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Details Modal */}
      <DetailsJobModal
        isOpen={isDetailsOpen}
        job={selectedJob}
        onClose={() => setIsDetailsOpen(false)}
      />

      {/* Edit Modal */}
      <EditJobModal
        isOpen={isEditOpen}
        job={selectedJob}
        editForm={editForm}
        setEditForm={setEditForm}
        onUpdate={handleUpdateJob}
        onClose={handleCloseEdit}
        handleAddQualification={handleAddQualification}
        handleQualificationChange={handleQualificationChange}
        handleRemoveQualification={handleRemoveQualification}
        handleAddInterviewees={handleAddInterviewees}
        handleIntervieweesChange={handleIntervieweesChange}
        handleRemoveInterviewees={handleRemoveInterviewees}
      />

      {/* Delete Confirmation Modal */}
      <DeleteConfirmationJobModal
        isOpen={isDeleteOpen}
        onDelete={handleDelete}
        onClose={() => setIsDeleteOpen(false)}
      />
      <ToastContainer position="top-right" autoClose={3000} />
    </div>
  );
}
