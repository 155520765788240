import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import AdminSidebar from "./AdminSidebar";
import JobPostingPanel from "./JobPostingPanel";
import ActiveJobsPanel from "./JobList/ActiveJobsPanel";
import ResumeList from "./Resume/ResumeList";
import UserList from "./UsersList";
import UserCreationForm from "./UserCreationForm";
import { useNavigate } from "react-router-dom";
import MeetingList from "./Meeting/MeetingList";
import AssignmentList from "./Assignment/AssignmentList";

export default function AdminLayout() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [userData, setUserData] = useState(null); // State to store user data
  const navigate = useNavigate();

  // Function to toggle dropdown
  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem("userEmail"); // Remove email from local storage
    localStorage.removeItem("refreshToken"); // Remove token from local storage
    navigate("/login"); // Redirect to login page
  };

  useEffect(() => {
    const fetchUserData = async () => {
      const email = localStorage.getItem("userEmail"); // Get email from localStorage
      if (email) {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API}/user/${email}`
          );
          const data = await response.json();
          setUserData(data); // Store user data in state

          // Assuming the response contains a refreshToken
          const refreshToken = data.refreshToken;
          if (refreshToken) {
            localStorage.setItem("refreshToken", refreshToken); // Set refreshToken in localStorage
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };
    fetchUserData();
  }, []);
  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      {/* Header */}
      <header className="bg-white py-4 px-6 flex items-center justify-between shadow-md w-full">
        <div className="flex items-center">
          {/* Menu button for mobile */}
          <button
            className="md:hidden mr-2 p-2 bg-transparent border-none focus:outline-none"
            onClick={() => setIsSidebarOpen((prev) => !prev)}
          >
            <svg
              className="h-6 w-6 text-gray-800"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              ></path>
            </svg>
          </button>
          <h1 className="text-xl font-bold text-gray-800">Starlabs Admin</h1>
        </div>

        <div className="flex items-center space-x-4">
          {/* Dropdown for user profile */}
          <div className="relative">
            <button
              onClick={handleDropdownToggle}
              className="flex items-center space-x-2 p-2 rounded-lg focus:outline-none hover:bg-gray-100"
            >
              <div className="h-8 w-8 rounded-full bg-gray-200 overflow-hidden">
                <img
                  src="/image.png"
                  alt={userData ? userData.name : "Admin"}
                  className="h-full w-full object-cover"
                />
              </div>
              <div className="text-sm font-medium text-gray-700">
                <span>{userData ? userData.name : "Admin"}</span>
                <span className="ml-2 text-xs text-gray-500">
                  {userData ? `(${userData.dept})` : ""}
                </span>
              </div>
              <svg
                className="h-4 w-4 text-gray-500"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                ></path>
              </svg>
            </button>

            {/* Dropdown menu */}
            {isDropdownOpen && (
              <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-lg shadow-lg z-10">
                <div className="py-2">
                  <span
                    className="block px-4 py-2 text-sm text-gray-700 font-semibold truncate"
                    title={userData ? userData.workEmail : "Profile"} // Tooltip on hover for full email
                  >
                    {userData ? userData.workEmail : "Profile"}
                  </span>
                  <span className="block px-4 py-2 text-sm text-gray-500">
                    {userData ? `Role: ${userData.role}` : "Role: Admin"}
                  </span>
                  <span className="block px-4 py-2 text-sm text-gray-500">
                    {userData ? `Department: ${userData.dept}` : "Department"}
                  </span>
                  <hr className="border-gray-200" />
                  <button
                    onClick={handleLogout}
                    className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    Log out
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </header>

      {/* Main content */}
      <div className="flex flex-1">
        {/* Sidebar */}
        <AdminSidebar
          isOpen={isSidebarOpen}
          onClose={() => setIsSidebarOpen(false)}
          className={`transition-transform transform ${
            isSidebarOpen ? "translate-x-0" : "-translate-x-full"
          } fixed md:relative md:translate-x-0 bg-white shadow-lg md:shadow-none z-30 w-64 h-full`}
        />

        <main className="flex-1 p-6 mt-12 md:mt-0 overflow-x-auto max-w-full">
          <Routes>
            <Route path="dashboard" element={<div>Dashboard Content</div>} />
            <Route path="post-job" element={<JobPostingPanel />} />
            <Route path="active-jobs" element={<ActiveJobsPanel />} />
            <Route path="resume" element={<ResumeList />} />
            <Route path="users" element={<UserList />} />
            <Route path="auth" element={<UserCreationForm />} />
            <Route path="meeting" element={<MeetingList />} />
            <Route path="assignment" element={<AssignmentList />} />
            <Route path="settings" element={<div>Settings Content</div>} />
            <Route path="*" element={<div>404 - Not Found</div>} />
          </Routes>
        </main>
      </div>
    </div>
  );
}
