import React from "react";
import { Navigate } from "react-router-dom";

// PrivateRoute component to protect admin routes
const PrivateRoute = ({ children }) => {
  const email = localStorage.getItem("userEmail");

  return email ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
