import { X } from "lucide-react";
import React, { useState, useEffect, useRef } from "react";
import { toast, ToastContainer } from "react-toastify";

const MeetingDetailsModal = ({ isOpen, onClose, meetingId, onUpdate }) => {
  const [meeting, setMeeting] = useState(null);
  const [status, setStatus] = useState("unscheduled");
  const modalRef = useRef();

  // Fetch meeting details when the modal is opened
  useEffect(() => {
    if (isOpen && meetingId) {
      fetchMeetingDetails(meetingId);
    }
  }, [isOpen, meetingId]);

  const fetchMeetingDetails = async (id) => {
    try {
      const response = await fetch(
        `https://website-backend.starlabs.co.in/api/meetings/${id}`
      );
      const data = await response.json();
      setMeeting(data);
      setStatus(data.status || "unscheduled");
    } catch (error) {
      console.error("Error fetching meeting details:", error);
    }
  };

  const handleSave = async () => {
    try {
      const response = await fetch(
        `https://website-backend.starlabs.co.in/api/meetings`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ id: meeting.id, status }),
        }
      );

      if (response.ok) {
        toast.success(response.message || "Status updated successfully");
        onUpdate();
        onClose();
      } else {
        toast.error("Failed to update status.");
        console.error("Failed to update meeting");
      }
    } catch (error) {
      console.error("Error updating meeting:", error);
    }
  };

  if (!isOpen || !meeting) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div
        ref={modalRef}
        className="bg-white rounded-lg shadow-lg p-6 w-full max-w-2xl mx-4"
      >
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">Meeting Details</h2>
          <button
            onClick={onClose}
            className="text-red-500 hover:text-red-700 focus:outline-none"
          >
            <X />
          </button>
        </div>

        {/* Meeting Details */}
        <div className="mb-6">
          <p>
            <strong>Name:</strong> {meeting.name}
          </p>
          <p>
            <strong>Email:</strong> {meeting.email}
          </p>
          <p>
            <strong>Role:</strong> {meeting.role}
          </p>
          <p>
            <strong>Date:</strong> {new Date(meeting.date).toLocaleDateString()}
          </p>
          <p>
            <strong>Time:</strong> {meeting.time}
          </p>
          <p>
            <strong>Status:</strong> {meeting.status}
          </p>
        </div>

        {/* Edit Status */}
        <div className="mb-6">
          <label className="block text-sm font-bold mb-2">Status</label>
          <div className="flex gap-2">
            <label>
              <input
                type="radio"
                value="scheduled"
                checked={status === "scheduled"}
                onChange={(e) => setStatus(e.target.value)}
              />{" "}
              Scheduled
            </label>
            <label>
              <input
                type="radio"
                value="unscheduled"
                checked={status === "unscheduled"}
                onChange={(e) => setStatus(e.target.value)}
              />{" "}
              Unscheduled
            </label>
          </div>
        </div>

        {/* Save Button */}
        <div className="flex justify-end">
          <button
            onClick={handleSave}
            className="bg-blue-500 text-white py-2 px-4 rounded mr-4"
          >
            Save
          </button>
          <button
            onClick={onClose}
            className="bg-red-500 text-white py-2 px-4 rounded"
          >
            Cancel
          </button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default MeetingDetailsModal;
