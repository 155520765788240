import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const OtpVerification = () => {
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();

  // Get email from localStorage
  const email = localStorage.getItem("userEmail");

  const handleOtpSubmit = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API}/user/verify`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, otp }),
      });

      const data = await response.json();

      if (data.status === true) {
        toast.success(
          "OTP verified successfully! Redirecting to reset password."
        );
        setTimeout(() => {
          navigate("/reset-password");
        }, 2000);
      } else if (data.workEmail === `${email}`) {
        toast.success(`${data.message}. Redirecting to admin dashboard.`);
        setTimeout(() => {
          navigate("/admin/post-job");
        }, 2000);
      } else {
        toast.error("Invalid OTP. Please try again.");
      }
    } catch (error) {
      console.error("OTP verification error:", error);
      toast.error("An error occurred during OTP verification.");
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white shadow-lg rounded-lg p-8 max-w-md w-full">
        <h1 className="text-2xl font-bold text-center mb-6 text-gray-800">
          OTP Verification
        </h1>
        <p className="mb-4 text-center text-gray-600">Email: {email}</p>
        <input
          type="text"
          placeholder="Enter OTP"
          value={otp}
          onChange={(e) => setOtp(e.target.value)}
          className="w-full px-4 py-2 border border-gray-300 rounded-md mb-4 focus:outline-none focus:ring-2 focus:ring-indigo-500"
        />
        <button
          onClick={handleOtpSubmit}
          className="w-full bg-indigo-600 text-white py-2 rounded-md hover:bg-indigo-700 transition"
        >
          Verify OTP
        </button>

        <ToastContainer />
      </div>
    </div>
  );
};

export default OtpVerification;
