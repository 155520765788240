import React, { useState, useEffect } from "react";
import Modal from "./Modal";

const EditResumeModal = ({ isOpen, onClose, resume, onSave }) => {
  const [status, setStatus] = useState("new");

  // Update status when the modal opens or resume changes
  useEffect(() => {
    if (resume && resume.status) {
      setStatus(resume.status);
    }
  }, [resume]);

  const handleSave = () => {
    onSave(status); // Call the onSave callback with the updated status
    onClose(); // Close the modal after saving
  };

  if (!resume) {
    return null; // Return null if no resume is passed to avoid errors
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <h2 className="text-lg font-bold mb-4">Edit Resume Status</h2>
      <form>
        <div className="mb-4">
          <label className="block text-sm font-bold mb-2">Status</label>
          <div className="flex gap-2">
            {/* Radio buttons for selecting status */}
            <label>
              <input
                type="radio"
                value="new"
                checked={status === "new"}
                onChange={(e) => setStatus(e.target.value)}
              />{" "}
              New
            </label>
            <label>
              <input
                type="radio"
                value="hold"
                checked={status === "hold"}
                onChange={(e) => setStatus(e.target.value)}
              />{" "}
              Hold
            </label>
            <label>
              <input
                type="radio"
                value="shortlisted"
                checked={status === "shortlisted"}
                onChange={(e) => setStatus(e.target.value)}
              />{" "}
              Shortlisted
            </label>
            <label>
              <input
                type="radio"
                value="sent"
                checked={status === "sent"}
                onChange={(e) => setStatus(e.target.value)}
              />{" "}
              Sent
            </label>
          </div>
        </div>
        {/* Save button */}
        <button
          type="button"
          className="bg-blue-500 text-white py-2 px-4 rounded"
          onClick={handleSave}
        >
          Save
        </button>
      </form>
    </Modal>
  );
};

export default EditResumeModal;
