// DeleteConfirmationModal.js
import React from "react";

export default function DeleteConfirmationJobModal({
  isOpen,
  onDelete,
  onClose,
}) {
  if (!isOpen) return null;

  const handleBackgroundClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
      onClick={handleBackgroundClick}
    >
      <div className="bg-white p-6 rounded-md shadow-md max-w-md w-full">
        <h2 className="text-lg font-bold mb-4">Confirm Deletion</h2>
        <p>Are you sure you want to delete this job?</p>
        <div className="flex justify-end mt-4">
          <button
            onClick={onDelete}
            className="bg-red-600 text-white py-2 px-4 rounded mr-2"
          >
            Delete
          </button>
          <button
            onClick={onClose}
            className="bg-gray-300 text-black py-2 px-4 rounded"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}
