import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function UserCreationForm() {
  const [newUser, setNewUser] = useState({
    name: "",
    workEmail: "",
    password: "",
    phone: "",
    role: "",
    personalEmail: "",
    dept: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewUser((prev) => ({ ...prev, [name]: value }));
  };

  const handleCreateUser = async (e) => {
    e.preventDefault();

    const userData = {
      name: newUser.name,
      workEmail: newUser.workEmail,
      password: newUser.password,
      phone: newUser.phone,
      role: newUser.role,
      personalEmail: newUser.personalEmail,
      dept: newUser.dept,
    };

    try {
      // Get the refresh token from localStorage
      const token = localStorage.getItem("refreshToken");

      const response = await fetch(`${process.env.REACT_APP_API}/user/signup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(userData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      toast.success(result.message || "User created successfully!");
      setNewUser({
        name: "",
        workEmail: "",
        password: "",
        phone: "",
        role: "",
        personalEmail: "",
        dept: "",
      });
    } catch (error) {
      console.error("Error creating user:", error);
      toast.error("Failed to create user. Please try again.");
    }
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-6">
      <div className="mb-4">
        <h2 className="text-lg font-bold">Create New User</h2>
      </div>
      <form onSubmit={handleCreateUser} className="space-y-4">
        {/* Name Input */}
        <div>
          <label
            htmlFor="name"
            className="block text-sm font-medium text-gray-700"
          >
            Name
          </label>
          <input
            id="name"
            name="name"
            type="text"
            value={newUser.name}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>

        {/* Work Email Input */}
        <div>
          <label
            htmlFor="workEmail"
            className="block text-sm font-medium text-gray-700"
          >
            Work Email
          </label>
          <input
            id="workEmail"
            name="workEmail"
            type="email"
            value={newUser.workEmail}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>

        {/* Password Input */}
        <div>
          <label
            htmlFor="password"
            className="block text-sm font-medium text-gray-700"
          >
            Password
          </label>
          <input
            id="password"
            name="password"
            type="password"
            value={newUser.password}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>

        {/* Phone Input */}
        <div>
          <label
            htmlFor="phone"
            className="block text-sm font-medium text-gray-700"
          >
            Phone
          </label>
          <input
            id="phone"
            name="phone"
            type="text"
            value={newUser.phone}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>

        {/* Role Input */}
        <div>
          <label
            htmlFor="role"
            className="block text-sm font-medium text-gray-700"
          >
            Role
          </label>
          <input
            id="role"
            name="role"
            type="text"
            value={newUser.role}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>

        {/* Personal Email Input */}
        <div>
          <label
            htmlFor="personalEmail"
            className="block text-sm font-medium text-gray-700"
          >
            Personal Email
          </label>
          <input
            id="personalEmail"
            name="personalEmail"
            type="email"
            value={newUser.personalEmail}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>

        {/* Department Input */}
        <div>
          <label
            htmlFor="dept"
            className="block text-sm font-medium text-gray-700"
          >
            Department
          </label>
          <input
            id="dept"
            name="dept"
            type="text"
            value={newUser.dept}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>

        <button
          type="submit"
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Create User
        </button>
      </form>
      <ToastContainer />
    </div>
  );
}
