import React from "react";
import {
  Users,
  FileText,
  BriefcaseBusiness,
  FileLock,
  FolderDot,
  Calendar,
  FileCode,
} from "lucide-react";
import { useNavigate } from "react-router-dom";

export default function AdminSidebar({ isOpen, onClose }) {
  const navigate = useNavigate();

  const menuItems = [
    // {
    //   icon: <LayoutDashboard className="mr-2 h-4 w-4" />,
    //   label: "Dashboard",
    //   route: "/admin/dashboard",
    // },
    {
      icon: <BriefcaseBusiness className="mr-2 h-4 w-4" />,
      label: "Post Job",
      route: "/admin/post-job",
    },
    {
      icon: <FolderDot className="mr-2 h-4 w-4" />,
      label: "Active Jobs",
      route: "/admin/active-jobs",
    },
    {
      icon: <FileText className="mr-2 h-4 w-4" />,
      label: "Resume",
      route: "/admin/resume",
    },
    {
      icon: <Users className="mr-2 h-4 w-4" />,
      label: "Users",
      route: "/admin/users",
    },
    {
      icon: <FileLock className="mr-2 h-4 w-4" />,
      label: "Auth",
      route: "/admin/auth",
    },
    {
      icon: <Calendar className="mr-2 h-4 w-4" />,
      label: "Meeting",
      route: "/admin/meeting",
    },
    {
      icon: <FileCode className="mr-2 h-4 w-4" />,
      label: "Assignment",
      route: "/admin/assignment",
    },
  ];

  const SidebarContent = () => (
    <div className="h-full py-6 overflow-y-auto bg-gray-200">
      <div className="flex justify-between items-center px-4 mb-4">
        <h2 className="text-lg font-semibold tracking-tight text-gray-800">
          Admin Panel
        </h2>
      </div>
      <div className="space-y-1 px-2">
        {menuItems.map((item) => (
          <button
            key={item.route}
            className="flex items-center w-full px-4 py-2 text-left text-gray-700 hover:bg-gray-100 rounded-lg"
            onClick={() => {
              navigate(item.route);
              onClose();
            }}
          >
            {item.icon}
            {item.label}
          </button>
        ))}
      </div>
    </div>
  );

  return (
    <>
      {/* Mobile Sidebar */}
      {isOpen && (
        <div className="fixed inset-0 z-40 flex">
          <div
            className="fixed inset-0 bg-black opacity-50"
            onClick={onClose}
          ></div>
          <div className="relative flex-1 w-64 bg-white p-0">
            <SidebarContent />
          </div>
        </div>
      )}

      {/* Desktop Sidebar */}
      <div className="hidden md:block w-64 bg-white border-r">
        <SidebarContent />
      </div>
    </>
  );
}
