// EditJobModal.js
import React from "react";
import { FaTrash } from "react-icons/fa";

export default function EditJobModal({
  isOpen,
  job,
  editForm,
  countries,
  setEditForm,
  onUpdate,
  onClose,
  handleAddQualification,
  handleAddInterviewees,
  handleQualificationChange,
  handleIntervieweesChange,
  handleRemoveQualification,
  handleRemoveInterviewees,
}) {
  if (!isOpen) return null;

  const handleBackgroundClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
      onClick={handleBackgroundClick}
    >
      <div className="bg-white p-6 rounded-md shadow-md max-w-3xl w-full max-h-screen overflow-y-auto">
        <h2 className="text-lg font-bold mb-4">Edit Job</h2>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onUpdate();
          }}
        >
          <div className="mb-4">
            <label className="block text-gray-700 mb-2">Status</label>
            <div className="flex gap-4">
              <label className="flex items-center">
                <input
                  type="radio"
                  name="status"
                  value="active"
                  checked={editForm.status === "active"}
                  onChange={(e) =>
                    setEditForm({ ...editForm, status: e.target.value })
                  }
                  className="mr-2"
                />
                Active
              </label>
              <label className="flex items-center">
                <input
                  type="radio"
                  name="status"
                  value="hold"
                  checked={editForm.status === "hold"}
                  onChange={(e) =>
                    setEditForm({ ...editForm, status: e.target.value })
                  }
                  className="mr-2"
                />
                Hold
              </label>
            </div>
          </div>

          <div className="mb-4">
            <label className="block text-gray-700">Role</label>
            <input
              type="text"
              value={editForm.role}
              onChange={(e) =>
                setEditForm({ ...editForm, role: e.target.value })
              }
              className="border border-gray-300 p-2 w-full rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Description</label>
            <textarea
              value={editForm.description}
              onChange={(e) =>
                setEditForm({ ...editForm, description: e.target.value })
              }
              className="border border-gray-300 p-2 w-full rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Qualifications</label>
            {editForm.qualifications.map((qualification, index) => (
              <div key={index} className="flex gap-2 mb-2">
                <input
                  type="text"
                  value={qualification}
                  onChange={(e) =>
                    handleQualificationChange(index, e.target.value)
                  }
                  className="border border-gray-300 p-2 flex-1 rounded"
                  required
                />
                <button
                  type="button"
                  onClick={() => handleRemoveQualification(index)}
                  className="text-red-600 hover:text-red-900"
                >
                  <FaTrash />
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={handleAddQualification}
              className="text-blue-600 hover:text-blue-900"
            >
              Add Qualification
            </button>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Location</label>
            <input
              type="text"
              value={editForm.location}
              onChange={(e) =>
                setEditForm({ ...editForm, location: e.target.value })
              }
              className="border border-gray-300 p-2 w-full rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Experience</label>
            <input
              type="text"
              value={editForm.experience}
              onChange={(e) =>
                setEditForm({ ...editForm, experience: e.target.value })
              }
              className="border border-gray-300 p-2 w-full rounded"
              required
            />
          </div>
          {/* <div className="mb-4">
            <label className="block text-gray-700">Country</label>
            <select
              value={editForm.country}
              onChange={(e) =>
                setEditForm({ ...editForm, country: e.target.value })
              }
              className="border border-gray-300 p-2 w-full rounded"
            >
              {countries.map((country) => (
                <option key={country} value={country}>
                  {country}
                </option>
              ))}
            </select>
          </div> */}
          <div className="mb-4">
            <label className="block text-gray-700">Country</label>
            <input
              type="text"
              value={editForm.country}
              onChange={(e) =>
                setEditForm({ ...editForm, country: e.target.value })
              }
              className="border border-gray-300 p-2 w-full rounded"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700">Interviewees</label>
            {editForm.interviewees.map((interviewees, index) => (
              <div key={index} className="flex gap-2 mb-2">
                <input
                  type="text"
                  value={interviewees}
                  onChange={(e) =>
                    handleIntervieweesChange(index, e.target.value)
                  }
                  className="border border-gray-300 p-2 flex-1 rounded"
                  required
                />
                <button
                  type="button"
                  onClick={() => handleRemoveInterviewees(index)}
                  className="text-red-600 hover:text-red-900"
                >
                  <FaTrash />
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={handleAddInterviewees}
              className="text-blue-600 hover:text-blue-900"
            >
              Add Interviewees
            </button>
          </div>

          <div className="mb-4">
            <label className="block text-gray-700">Email Body</label>
            <textarea
              value={editForm.emailBody}
              onChange={(e) =>
                setEditForm({ ...editForm, emailBody: e.target.value })
              }
              className="border border-gray-300 p-2 w-full rounded"
              required
            />
          </div>

          <div className="flex justify-end">
            <button
              type="button"
              onClick={onClose}
              className="mr-2 px-4 py-2 bg-gray-300 rounded"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded"
            >
              Update Job
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
