import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function JobPostingPanel() {
  const [newJob, setNewJob] = useState({
    title: "",
    department: "",
    role: "",
    description: "",
    experience: "",
    qualifications: [""],
    location: "",
    country: "",
    interviewees: [""],
    emailBody: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewJob((prev) => ({ ...prev, [name]: value }));
  };

  const handleQualificationChange = (index, value) => {
    const updatedQualifications = [...newJob.qualifications];
    updatedQualifications[index] = value;
    setNewJob((prev) => ({ ...prev, qualifications: updatedQualifications }));
  };

  const handleIntervieweesChange = (index, value) => {
    const updatedInterviewees = [...newJob.interviewees];
    updatedInterviewees[index] = value;
    setNewJob((prev) => ({ ...prev, interviewees: updatedInterviewees }));
  };

  const handleAddQualification = () => {
    setNewJob((prev) => ({
      ...prev,
      qualifications: [...prev.qualifications, ""],
    }));
  };

  const handleAddInterviewees = () => {
    setNewJob((prev) => ({
      ...prev,
      interviewees: [...prev.interviewees, ""],
    }));
  };

  const handleAddJob = async (e) => {
    e.preventDefault();
    const jobData = {
      role: newJob.role,
      description: newJob.description,
      experience: newJob.experience,
      qualifications: newJob.qualifications, // Keep as an array
      location: newJob.location,
      country: newJob.country,
      interviewees: newJob.interviewees,
      emailBody: newJob.emailBody,
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_API}/jobs`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(jobData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      console.log("Job posted:", result);
      toast.success("Job posted successfully!");
      setNewJob({
        role: "",
        experience: "",
        description: "",
        qualifications: [""],
        location: "",
        country: "",
        interviewees: [""],
        emailBody: "",
      });
    } catch (error) {
      console.error("Error posting job:", error);
      toast.error("Failed to post job. Please try again.");
    }
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-6">
      <div className="mb-4">
        <h2 className="text-lg font-bold">Post New Job</h2>
      </div>
      <form onSubmit={handleAddJob} className="space-y-4">
        {/* Role Input */}
        <div>
          <label
            htmlFor="role"
            className="block text-sm font-medium text-gray-700"
          >
            Job Role
          </label>
          <input
            id="role"
            name="role"
            type="text"
            value={newJob.role}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>

        {/* Experience Input */}
        <div>
          <label
            htmlFor="experience"
            className="block text-sm font-medium text-gray-700"
          >
            Experience
          </label>
          <input
            id="experience"
            name="experience"
            type="text"
            value={newJob.experience}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>

        {/* Description Input */}
        <div>
          <label
            htmlFor="description"
            className="block text-sm font-medium text-gray-700"
          >
            Job Description
          </label>
          <textarea
            id="description"
            name="description"
            value={newJob.description}
            onChange={handleInputChange}
            rows={4}
            required
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>

        {/* Qualifications Input */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Qualifications
          </label>
          {newJob.qualifications.map((qualification, index) => (
            <div key={index} className="flex items-center space-x-2 mb-2">
              <input
                type="text"
                value={qualification}
                onChange={(e) =>
                  handleQualificationChange(index, e.target.value)
                }
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                placeholder={`Qualification ${index + 1}`}
              />
            </div>
          ))}
          <button
            type="button"
            onClick={handleAddQualification}
            className="mt-2 text-indigo-600 hover:text-indigo-900"
          >
            + Add Qualification
          </button>
        </div>

        {/* Location Input */}
        <div>
          <label
            htmlFor="location"
            className="block text-sm font-medium text-gray-700"
          >
            Location
          </label>
          <input
            id="location"
            name="location"
            type="text"
            value={newJob.location}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>

        {/* Country Dropdown */}
        <div>
          <label
            htmlFor="country"
            className="block text-sm font-medium text-gray-700"
          >
            Country
          </label>
          <input
            id="country"
            name="country"
            type="text"
            value={newJob.country}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>

        {/* Interviewees*/}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Interviewees
          </label>
          {newJob.interviewees.map((interviewees, index) => (
            <div key={index} className="flex items-center space-x-2 mb-2">
              <input
                type="text"
                value={interviewees}
                onChange={(e) =>
                  handleIntervieweesChange(index, e.target.value)
                }
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                placeholder={`Interviewees ${index + 1}`}
              />
            </div>
          ))}
          <button
            type="button"
            onClick={handleAddInterviewees}
            className="mt-2 text-indigo-600 hover:text-indigo-900"
          >
            + Add Interviewees
          </button>
        </div>

        {/* Email Body */}
        <div>
          <label
            htmlFor="emailBody"
            className="block text-sm font-medium text-gray-700"
          >
            Email Body
          </label>
          <textarea
            id="emailBody"
            name="emailBody"
            value={newJob.emailBody}
            onChange={handleInputChange}
            rows={4}
            required
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>

        <button
          type="submit"
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Post Job
        </button>
      </form>
      <ToastContainer />
    </div>
  );
}
