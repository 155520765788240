import React, { useState, useEffect } from "react";
import { FaInfoCircle, FaTrash } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function UserList() {
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null); // For more info modal
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null); // For delete confirmation

  // Fetch users when component mounts or currentPage changes
  useEffect(() => {
    fetchUsers(currentPage);
  }, [currentPage]);

  const fetchUsers = async (page) => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API}/user?page=${page}&limit=5`
      );
      const data = await response.json();
      setUsers(data.employeeDetails || []);
      setTotalPages(data.totalPages || 1);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching users:", error);
      toast.error("Failed to fetch users.");
      setLoading(false);
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const openInfoModal = async (email) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API}/user/${email}`
      );
      const user = await response.json();
      setSelectedUser(user);
      setShowInfoModal(true);
    } catch (error) {
      toast.error("Failed to fetch user info.");
    }
  };

  const openDeleteModal = (user) => {
    setUserToDelete(user);
    setShowDeleteModal(true);
  };

  const handleDeleteUser = async () => {
    try {
      const token = localStorage.getItem("refreshToken");
      const response = await fetch(`${process.env.REACT_APP_API}/user`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ email: userToDelete.workEmail }),
      });
      const data = await response.json();
      if (data.message === "Employee deleted successfully") {
        toast.success("User deleted successfully");
        setUsers(users.filter((u) => u.id !== userToDelete.id));
        setShowDeleteModal(false);
      } else {
        toast.error("Failed to delete user");
      }
    } catch (error) {
      toast.error("Error deleting user");
    }
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-6">
      <h2 className="text-lg font-bold mb-4">User List</h2>

      {loading ? (
        <div className="flex justify-center items-center h-64">
          <div className="flex space-x-3">
            {/* Loading animation */}
            <div className="w-4 h-4 bg-blue-400 rounded-full animate-pulse"></div>
            <div className="w-4 h-4 bg-blue-400 rounded-full animate-pulse delay-150"></div>
            <div className="w-4 h-4 bg-blue-400 rounded-full animate-pulse delay-300"></div>
          </div>
        </div>
      ) : (
        <>
          <div className="overflow-x-auto">
            <table className="table-auto min-w-full bg-white border border-gray-300">
              <thead>
                <tr className="bg-gray-100">
                  <th className="py-2 px-4 border-b">Name</th>
                  <th className="py-2 px-4 border-b">Work Email</th>
                  <th className="py-2 px-4 border-b">Phone</th>
                  <th className="py-2 px-4 border-b">Department</th>
                  <th className="py-2 px-4 border-b">Actions</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user) => (
                  <tr key={user.id} className="hover:bg-gray-50">
                    <td className="py-2 px-4 border-b break-words">
                      {user.name}
                    </td>
                    <td className="py-2 px-4 border-b break-words">
                      {user.workEmail}
                    </td>
                    <td className="py-2 px-4 border-b break-words">
                      {user.phone}
                    </td>
                    <td className="py-2 px-4 border-b break-words">
                      {user.dept}
                    </td>
                    <td className="py-2 px-4 border-b text-center align-middle">
                      <div className="flex justify-center items-center gap-2">
                        <button
                          onClick={() => openInfoModal(user.workEmail)}
                          className="text-blue-600 hover:text-blue-900"
                        >
                          <FaInfoCircle className="text-blue-600" />
                        </button>
                        <button
                          onClick={() => openDeleteModal(user)}
                          className="text-red-600 hover:text-red-900"
                        >
                          <FaTrash className="text-red-600" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Pagination */}
          <div className="flex justify-between items-center mt-4">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              className={`py-2 px-4 rounded ${
                currentPage === 1
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-600 text-white hover:bg-blue-700"
              }`}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              className={`py-2 px-4 rounded ${
                currentPage === totalPages
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-600 text-white hover:bg-blue-700"
              }`}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </>
      )}

      {/* More Info Modal */}
      {showInfoModal && selectedUser && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white rounded-lg p-6">
            <h3 className="text-lg font-bold mb-4">User Details</h3>
            <p>
              <strong>Name:</strong> {selectedUser.name}
            </p>
            <p>
              <strong>Work Email:</strong> {selectedUser.workEmail}
            </p>
            <p>
              <strong>Phone:</strong> {selectedUser.phone}
            </p>
            <p>
              <strong>Department:</strong> {selectedUser.dept}
            </p>
            <p>
              <strong>Created By:</strong> {selectedUser.createdBy}
            </p>
            <button
              onClick={() => setShowInfoModal(false)}
              className="mt-4 bg-blue-500 text-white py-1 px-3 rounded hover:bg-blue-600"
            >
              Close
            </button>
          </div>
        </div>
      )}

      {/* Delete Confirmation Modal */}
      {showDeleteModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white rounded-lg p-6">
            <h3 className="text-lg font-bold mb-4">Confirm Delete</h3>
            <p>Are you sure you want to delete {userToDelete?.name}?</p>
            <div className="flex justify-end mt-4">
              <button
                onClick={() => setShowDeleteModal(false)}
                className="bg-gray-300 text-gray-800 py-1 px-3 rounded mr-2 hover:bg-gray-400"
              >
                Cancel
              </button>
              <button
                onClick={handleDeleteUser}
                className="bg-red-500 text-white py-1 px-3 rounded hover:bg-red-600"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}

      <ToastContainer />
    </div>
  );
}
